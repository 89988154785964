<template>
  <div class="h-screen flex flex-col overflow-y-auto bg-white">
    <home-header breadcrumb />
    <main class="flex-1 relative focus:outline-none" tabindex="0">
      <div class="m-4 p-4 focus:outline-none bg-gray-100">
        <div
          class="flex flex-1 flex-col justify-evenly items-center justify-start"
        >
          <router-link
            :to="{ path: '1' }"
            append
            class="flex flex-col m-4 text-black h-full rounded-xl bg-white border shadow justify-self-center object-center hover:shadow-2xl p-2"
          >
            <img
              :src="getImgUrl('ppsea_logo.png')"
              class="w-auto h-32 md:h-40 lg:h-64 2xl:h-80 object-contain"
            />
            <div class="p-2 place-content-center">
              <p
                class="text-sm lg:text-base 2xl:text-lg text-center font-semibold"
              >
                Provincial Priory of South East Asia
              </p>
            </div>
          </router-link>
          <div
            class="flex text-red-700 font-serif font-bold text-sm md:text-base lg:text-lg justify-self-start text-justify p-2 lg:p-4 rounded-md"
          >
            <img
              class="hidden xl:block object-contain w-1/2 p-2"
              src="../../assets/kt-drbrian.jpg"
            />
            <div class="px-4">
              <p class="mb-4 mt-2">
                I am delighted that after 30 years of existence the Provincial
                Priory of South East Asia (PPSEA) has achieved this milestone of
                establishing a website to include all its constituent
                Preceptories. This is an aspiration that I announced on my
                installation as Provincial Prior on 21st October 2017. It gives
                me profound pleasure and gratification that PPSEA has
                established a website of most impressive dimensions.
              </p>
              <p class="mb-4 mt-2">
                I am convinced that our website must be one of the most
                comprehensive of any website in our Order. Our database of
                members with its impressive search function is an example of the
                thought and efforts that have been invested in this website.
              </p>
              <p class="mb-4 mt-2">
                There is a vast amount of resource material on Knights Templar
                and Masonry in terms of articles, presentations, speeches,
                e-books and e-links that are available within the website which
                our members should fully utilise.
              </p>
              <p class="mb-4 mt-2">
                One of the objectives of the website is the encourage members to
                regularly visit and be aware of KT meetings, social and
                charitable activities that are widely undertaken across PPSEA.
                Individual Preceptories have their separate pages, gallery of
                photos and privacy requirement are fully covered. Finally, this
                project would not have come to fruition without the leadership
                of Em Kt Russell Stuart, chairman of the website project, his
                enthusiastic committee members comprising representatives from
                the various Preceptories and our brilliant webmaster, Bro Kt
                Randall Lee.
              </p>
              <br />
              <div class="mb-4 mt-2 flex justify-between">
                <p>R Em Kt Dr Brian Shegar <br />Provincial Prior</p>
              </div>
            </div>
          </div>
        </div>
        <div class="p-2">
          <img
            class="block xl:hidden overflow-scroll max-h-full object-contain w-full"
            src="../../assets/kt-drbrian.jpg"
          />
        </div>
      </div>
    </main>
    <home-footer />
  </div>
</template>

<script>
import { HomeHeader } from "../../layouts";
import { HomeFooter } from "../../components";

export default {
  name: "Priory",
  components: {
    HomeHeader,
    HomeFooter,
  },
  mounted() {},
  data() {
    return {
      items: [
        {
          id: "1",
          url: "ppsea_logo.png",
          name: "Provincial Priory of South East Asia",
        },
      ],
    };
  },
  methods: {
    // Needed for webpack static asset handling
    getImgUrl(logo) {
      return require("../../assets/" + logo);
    },
  },
};
</script>
